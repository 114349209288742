import { createIcon } from '@chakra-ui/react';
import { FC, memo } from 'react';
import { iconSizes } from './constants';
import { Sizes } from './types';

const Ic = createIcon({
  viewBox: '0 0 24 24',
  path: [
    <g
      key='path-1'
      clipPath='url(#clip0_3002_85008)'
    >
      <path
        d='M19.0155 20.4917H4.98449C2.23163 20.4917 0 18.2601 0 15.5072V8.49319C0 5.74032 2.23163 3.50869 4.98449 3.50869H19.0155C21.7684 3.50869 24 5.74032 24 8.49319V15.5072C24 18.2601 21.7684 20.4917 19.0155 20.4917ZM16.6445 11.6589L10.0818 8.5289C9.9069 8.4455 9.7049 8.57299 9.7049 8.76671V15.2224C9.7049 15.4189 9.91221 15.5463 10.0874 15.4574L16.6502 12.1318C16.8453 12.0329 16.8419 11.7531 16.6445 11.6589Z'
        fill='currentColor'
      />
    </g>,
    <defs key='path-2'>
      <clipPath id='clip0_3002_85008'>
        <rect
          width='24'
          height='24'
          fill='currentColor'
          transform='matrix(1 0 0 -1 0 24)'
        />
      </clipPath>
    </defs>,
  ],
});

type Props = {
  size?: keyof Sizes;
};

const IconSocialYoutube: FC<Props> = ({ size = 'lg', ...props }) => (
  <Ic
    {...iconSizes[size]}
    {...props}
  />
);

export default memo(IconSocialYoutube);
