import { createIcon } from '@chakra-ui/react';
import { FC, memo } from 'react';
import { iconSizes } from './constants';
import { IconPropsWithSize } from './types';

const Ic = createIcon({
  viewBox: '0 0 24 24',
  path: (
    <path
      d='M19.3214 5.56219C19.1695 5.4837 19.0217 5.39765 18.8784 5.30437C18.4618 5.02896 18.0799 4.70445 17.7408 4.33781C16.8923 3.36703 16.5754 2.38219 16.4587 1.69266H16.4634C16.3659 1.12031 16.4062 0.75 16.4123 0.75H12.5479V15.6928C12.5479 15.8934 12.5479 16.0917 12.5395 16.2877C12.5395 16.312 12.5372 16.3345 12.5358 16.3608C12.5358 16.3716 12.5358 16.3828 12.5334 16.3941C12.5334 16.3969 12.5334 16.3997 12.5334 16.4025C12.4927 16.9386 12.3208 17.4566 12.0329 17.9107C11.7451 18.3648 11.35 18.7413 10.8825 19.0069C10.3952 19.2841 9.84414 19.4295 9.28357 19.4287C7.4831 19.4287 6.02388 17.9606 6.02388 16.1475C6.02388 14.3344 7.4831 12.8662 9.28357 12.8662C9.62439 12.8659 9.96311 12.9196 10.2872 13.0252L10.2918 9.09047C9.30811 8.9634 8.30872 9.04158 7.35671 9.32008C6.4047 9.59858 5.52074 10.0714 4.7606 10.7086C4.09454 11.2873 3.53457 11.9778 3.10591 12.7491C2.94279 13.0303 2.32732 14.1605 2.25279 15.9947C2.20591 17.0358 2.51857 18.1144 2.66763 18.5602V18.5695C2.76138 18.832 3.12466 19.7278 3.71669 20.483C4.19409 21.0887 4.75811 21.6208 5.3906 22.0622V22.0528L5.39997 22.0622C7.27076 23.3334 9.34497 23.25 9.34497 23.25C9.70404 23.2355 10.9068 23.25 12.2728 22.6027C13.7878 21.885 14.6503 20.8158 14.6503 20.8158C15.2013 20.1769 15.6394 19.4488 15.9459 18.6628C16.2956 17.7436 16.4123 16.6411 16.4123 16.2005V8.27297C16.4592 8.30109 17.0836 8.71406 17.0836 8.71406C17.0836 8.71406 17.9831 9.29062 19.3865 9.66609C20.3934 9.93328 21.75 9.98953 21.75 9.98953V6.15328C21.2747 6.20484 20.3095 6.05484 19.3214 5.56219Z'
      fill='currentColor'
    />
  ),
});

const IconSocialTiktok: FC<IconPropsWithSize> = ({ size = 'lg', ...props }) => (
  <Ic
    {...iconSizes[size]}
    {...props}
  />
);

export default memo(IconSocialTiktok);
