import { createIcon } from '@chakra-ui/react';
import { FC, memo } from 'react';
import { iconSizes } from './constants';
import { IconPropsWithSize } from './types';

const Ic = createIcon({
  viewBox: '0 0 24 24',
  path: [
    <path
      key='path-3'
      d='M14.6 6C14.6 7.43594 13.4359 8.6 12 8.6C10.5641 8.6 9.4 7.43594 9.4 6C9.4 4.56406 10.5641 3.4 12 3.4C13.4359 3.4 14.6 4.56406 14.6 6ZM16 6C16 8.20914 14.2091 10 12 10C9.79086 10 8 8.20914 8 6C8 3.79086 9.79086 2 12 2C14.2091 2 16 3.79086 16 6ZM6 20.6C5.66863 20.6 5.4 20.3314 5.4 20C5.4 16.3549 8.35492 13.4 12 13.4C15.6451 13.4 18.6 16.3549 18.6 20C18.6 20.3314 18.3314 20.6 18 20.6H6ZM4 20C4 15.5817 7.58172 12 12 12C16.4183 12 20 15.5817 20 20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20Z'
      stroke='currentColor'
      fill='transparent'
    />,
  ],
});

const IconUser: FC<IconPropsWithSize> = ({ size = 'lg', ...props }) => (
  <Ic
    {...iconSizes[size]}
    {...props}
  />
);

export default memo(IconUser);
